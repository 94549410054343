export const country = [
  {
    name: "Afghanistan",
    flag: "\uD83C\uDDE6\uD83C\uDDEB",
    code: "AF",
    dial_code: "+93",
  },
  {
    name: "\xc5land Islands",
    flag: "\uD83C\uDDE6\uD83C\uDDFD",
    code: "AX",
    dial_code: "+358",
  },
  {
    name: "Albania",
    flag: "\uD83C\uDDE6\uD83C\uDDF1",
    code: "AL",
    dial_code: "+355",
  },
  {
    name: "Algeria",
    flag: "\uD83C\uDDE9\uD83C\uDDFF",
    code: "DZ",
    dial_code: "+213",
  },
  {
    name: "American Samoa",
    flag: "\uD83C\uDDE6\uD83C\uDDF8",
    code: "AS",
    dial_code: "+1684",
  },
  {
    name: "Andorra",
    flag: "\uD83C\uDDE6\uD83C\uDDE9",
    code: "AD",
    dial_code: "+376",
  },
  {
    name: "Angola",
    flag: "\uD83C\uDDE6\uD83C\uDDF4",
    code: "AO",
    dial_code: "+244",
  },
  {
    name: "Anguilla",
    flag: "\uD83C\uDDE6\uD83C\uDDEE",
    code: "AI",
    dial_code: "+1264",
  },
  {
    name: "Antarctica",
    flag: "\uD83C\uDDE6\uD83C\uDDF6",
    code: "AQ",
    dial_code: "+672",
  },
  {
    name: "Antigua and Barbuda",
    flag: "\uD83C\uDDE6\uD83C\uDDEC",
    code: "AG",
    dial_code: "+1268",
  },
  {
    name: "Argentina",
    flag: "\uD83C\uDDE6\uD83C\uDDF7",
    code: "AR",
    dial_code: "+54",
  },
  {
    name: "Armenia",
    flag: "\uD83C\uDDE6\uD83C\uDDF2",
    code: "AM",
    dial_code: "+374",
  },
  {
    name: "Aruba",
    flag: "\uD83C\uDDE6\uD83C\uDDFC",
    code: "AW",
    dial_code: "+297",
  },
  {
    name: "Australia",
    flag: "\uD83C\uDDE6\uD83C\uDDFA",
    code: "AU",
    dial_code: "+61",
  },
  {
    name: "Austria",
    flag: "\uD83C\uDDE6\uD83C\uDDF9",
    code: "AT",
    dial_code: "+43",
  },
  {
    name: "Azerbaijan",
    flag: "\uD83C\uDDE6\uD83C\uDDFF",
    code: "AZ",
    dial_code: "+994",
  },
  {
    name: "Bahamas",
    flag: "\uD83C\uDDE7\uD83C\uDDF8",
    code: "BS",
    dial_code: "+1242",
  },
  {
    name: "Bahrain",
    flag: "\uD83C\uDDE7\uD83C\uDDED",
    code: "BH",
    dial_code: "+973",
  },
  {
    name: "Bangladesh",
    flag: "\uD83C\uDDE7\uD83C\uDDE9",
    code: "BD",
    dial_code: "+880",
  },
  {
    name: "Barbados",
    flag: "\uD83C\uDDE7\uD83C\uDDE7",
    code: "BB",
    dial_code: "+1246",
  },
  {
    name: "Belarus",
    flag: "\uD83C\uDDE7\uD83C\uDDFE",
    code: "BY",
    dial_code: "+375",
  },
  {
    name: "Belgium",
    flag: "\uD83C\uDDE7\uD83C\uDDEA",
    code: "BE",
    dial_code: "+32",
  },
  {
    name: "Belize",
    flag: "\uD83C\uDDE7\uD83C\uDDFF",
    code: "BZ",
    dial_code: "+501",
  },
  {
    name: "Benin",
    flag: "\uD83C\uDDE7\uD83C\uDDEF",
    code: "BJ",
    dial_code: "+229",
  },
  {
    name: "Bermuda",
    flag: "\uD83C\uDDE7\uD83C\uDDF2",
    code: "BM",
    dial_code: "+1441",
  },
  {
    name: "Bhutan",
    flag: "\uD83C\uDDE7\uD83C\uDDF9",
    code: "BT",
    dial_code: "+975",
  },
  {
    name: "Bolivia, Plurinational State of bolivia",
    flag: "\uD83C\uDDE7\uD83C\uDDF4",
    code: "BO",
    dial_code: "+591",
  },
  {
    name: "Bosnia and Herzegovina",
    flag: "\uD83C\uDDE7\uD83C\uDDE6",
    code: "BA",
    dial_code: "+387",
  },
  {
    name: "Botswana",
    flag: "\uD83C\uDDE7\uD83C\uDDFC",
    code: "BW",
    dial_code: "+267",
  },
  {
    name: "Bouvet Island",
    flag: "\uD83C\uDDE7\uD83C\uDDFB",
    code: "BV",
    dial_code: "+47",
  },
  {
    name: "Brazil",
    flag: "\uD83C\uDDE7\uD83C\uDDF7",
    code: "BR",
    dial_code: "+55",
  },
  {
    name: "British Indian Ocean Territory",
    flag: "\uD83C\uDDEE\uD83C\uDDF4",
    code: "IO",
    dial_code: "+246",
  },
  {
    name: "Brunei Darussalam",
    flag: "\uD83C\uDDE7\uD83C\uDDF3",
    code: "BN",
    dial_code: "+673",
  },
  {
    name: "Bulgaria",
    flag: "\uD83C\uDDE7\uD83C\uDDEC",
    code: "BG",
    dial_code: "+359",
  },
  {
    name: "Burkina Faso",
    flag: "\uD83C\uDDE7\uD83C\uDDEB",
    code: "BF",
    dial_code: "+226",
  },
  {
    name: "Burundi",
    flag: "\uD83C\uDDE7\uD83C\uDDEE",
    code: "BI",
    dial_code: "+257",
  },
  {
    name: "Cambodia",
    flag: "\uD83C\uDDF0\uD83C\uDDED",
    code: "KH",
    dial_code: "+855",
  },
  {
    name: "Cameroon",
    flag: "\uD83C\uDDE8\uD83C\uDDF2",
    code: "CM",
    dial_code: "+237",
  },
  {
    name: "Canada",
    flag: "\uD83C\uDDE8\uD83C\uDDE6",
    code: "CA",
    dial_code: "+1",
  },
  {
    name: "Cape Verde",
    flag: "\uD83C\uDDE8\uD83C\uDDFB",
    code: "CV",
    dial_code: "+238",
  },
  {
    name: "Cayman Islands",
    flag: "\uD83C\uDDF0\uD83C\uDDFE",
    code: "KY",
    dial_code: "+345",
  },
  {
    name: "Central African Republic",
    flag: "\uD83C\uDDE8\uD83C\uDDEB",
    code: "CF",
    dial_code: "+236",
  },
  {
    name: "Chad",
    flag: "\uD83C\uDDF9\uD83C\uDDE9",
    code: "TD",
    dial_code: "+235",
  },
  {
    name: "Chile",
    flag: "\uD83C\uDDE8\uD83C\uDDF1",
    code: "CL",
    dial_code: "+56",
  },
  {
    name: "China",
    flag: "\uD83C\uDDE8\uD83C\uDDF3",
    code: "CN",
    dial_code: "+86",
  },
  {
    name: "Christmas Island",
    flag: "\uD83C\uDDE8\uD83C\uDDFD",
    code: "CX",
    dial_code: "+61",
  },
  {
    name: "Cocos (Keeling) Islands",
    flag: "\uD83C\uDDE8\uD83C\uDDE8",
    code: "CC",
    dial_code: "+61",
  },
  {
    name: "Colombia",
    flag: "\uD83C\uDDE8\uD83C\uDDF4",
    code: "CO",
    dial_code: "+57",
  },
  {
    name: "Comoros",
    flag: "\uD83C\uDDF0\uD83C\uDDF2",
    code: "KM",
    dial_code: "+269",
  },
  {
    name: "Congo",
    flag: "\uD83C\uDDE8\uD83C\uDDEC",
    code: "CG",
    dial_code: "+242",
  },
  {
    name: "Congo, The Democratic Republic of the Congo",
    flag: "\uD83C\uDDE8\uD83C\uDDE9",
    code: "CD",
    dial_code: "+243",
  },
  {
    name: "Cook Islands",
    flag: "\uD83C\uDDE8\uD83C\uDDF0",
    code: "CK",
    dial_code: "+682",
  },
  {
    name: "Costa Rica",
    flag: "\uD83C\uDDE8\uD83C\uDDF7",
    code: "CR",
    dial_code: "+506",
  },
  {
    name: "Cote d'Ivoire",
    flag: "\uD83C\uDDE8\uD83C\uDDEE",
    code: "CI",
    dial_code: "+225",
  },
  {
    name: "Croatia",
    flag: "\uD83C\uDDED\uD83C\uDDF7",
    code: "HR",
    dial_code: "+385",
  },
  {
    name: "Cuba",
    flag: "\uD83C\uDDE8\uD83C\uDDFA",
    code: "CU",
    dial_code: "+53",
  },
  {
    name: "Cyprus",
    flag: "\uD83C\uDDE8\uD83C\uDDFE",
    code: "CY",
    dial_code: "+357",
  },
  {
    name: "Czech Republic",
    flag: "\uD83C\uDDE8\uD83C\uDDFF",
    code: "CZ",
    dial_code: "+420",
  },
  {
    name: "Denmark",
    flag: "\uD83C\uDDE9\uD83C\uDDF0",
    code: "DK",
    dial_code: "+45",
  },
  {
    name: "Djibouti",
    flag: "\uD83C\uDDE9\uD83C\uDDEF",
    code: "DJ",
    dial_code: "+253",
  },
  {
    name: "Dominica",
    flag: "\uD83C\uDDE9\uD83C\uDDF2",
    code: "DM",
    dial_code: "+1767",
  },
  {
    name: "Dominican Republic",
    flag: "\uD83C\uDDE9\uD83C\uDDF4",
    code: "DO",
    dial_code: "+1849",
  },
  {
    name: "Ecuador",
    flag: "\uD83C\uDDEA\uD83C\uDDE8",
    code: "EC",
    dial_code: "+593",
  },
  {
    name: "Egypt",
    flag: "\uD83C\uDDEA\uD83C\uDDEC",
    code: "EG",
    dial_code: "+20",
  },
  {
    name: "El Salvador",
    flag: "\uD83C\uDDF8\uD83C\uDDFB",
    code: "SV",
    dial_code: "+503",
  },
  {
    name: "Equatorial Guinea",
    flag: "\uD83C\uDDEC\uD83C\uDDF6",
    code: "GQ",
    dial_code: "+240",
  },
  {
    name: "Eritrea",
    flag: "\uD83C\uDDEA\uD83C\uDDF7",
    code: "ER",
    dial_code: "+291",
  },
  {
    name: "Estonia",
    flag: "\uD83C\uDDEA\uD83C\uDDEA",
    code: "EE",
    dial_code: "+372",
  },
  {
    name: "Ethiopia",
    flag: "\uD83C\uDDEA\uD83C\uDDF9",
    code: "ET",
    dial_code: "+251",
  },
  {
    name: "Falkland Islands (Malvinas)",
    flag: "\uD83C\uDDEB\uD83C\uDDF0",
    code: "FK",
    dial_code: "+500",
  },
  {
    name: "Faroe Islands",
    flag: "\uD83C\uDDEB\uD83C\uDDF4",
    code: "FO",
    dial_code: "+298",
  },
  {
    name: "Fiji",
    flag: "\uD83C\uDDEB\uD83C\uDDEF",
    code: "FJ",
    dial_code: "+679",
  },
  {
    name: "Finland",
    flag: "\uD83C\uDDEB\uD83C\uDDEE",
    code: "FI",
    dial_code: "+358",
  },
  {
    name: "France",
    flag: "\uD83C\uDDEB\uD83C\uDDF7",
    code: "FR",
    dial_code: "+33",
  },
  {
    name: "French Guiana",
    flag: "\uD83C\uDDEC\uD83C\uDDEB",
    code: "GF",
    dial_code: "+594",
  },
  {
    name: "French Polynesia",
    flag: "\uD83C\uDDF5\uD83C\uDDEB",
    code: "PF",
    dial_code: "+689",
  },
  {
    name: "French Southern Territories",
    flag: "\uD83C\uDDF9\uD83C\uDDEB",
    code: "TF",
    dial_code: "+262",
  },
  {
    name: "Gabon",
    flag: "\uD83C\uDDEC\uD83C\uDDE6",
    code: "GA",
    dial_code: "+241",
  },
  {
    name: "Gambia",
    flag: "\uD83C\uDDEC\uD83C\uDDF2",
    code: "GM",
    dial_code: "+220",
  },
  {
    name: "Georgia",
    flag: "\uD83C\uDDEC\uD83C\uDDEA",
    code: "GE",
    dial_code: "+995",
  },
  {
    name: "Germany",
    flag: "\uD83C\uDDE9\uD83C\uDDEA",
    code: "DE",
    dial_code: "+49",
  },
  {
    name: "Ghana",
    flag: "\uD83C\uDDEC\uD83C\uDDED",
    code: "GH",
    dial_code: "+233",
  },
  {
    name: "Gibraltar",
    flag: "\uD83C\uDDEC\uD83C\uDDEE",
    code: "GI",
    dial_code: "+350",
  },
  {
    name: "Greece",
    flag: "\uD83C\uDDEC\uD83C\uDDF7",
    code: "GR",
    dial_code: "+30",
  },
  {
    name: "Greenland",
    flag: "\uD83C\uDDEC\uD83C\uDDF1",
    code: "GL",
    dial_code: "+299",
  },
  {
    name: "Grenada",
    flag: "\uD83C\uDDEC\uD83C\uDDE9",
    code: "GD",
    dial_code: "+1473",
  },
  {
    name: "Guadeloupe",
    flag: "\uD83C\uDDEC\uD83C\uDDF5",
    code: "GP",
    dial_code: "+590",
  },
  {
    name: "Guam",
    flag: "\uD83C\uDDEC\uD83C\uDDFA",
    code: "GU",
    dial_code: "+1671",
  },
  {
    name: "Guatemala",
    flag: "\uD83C\uDDEC\uD83C\uDDF9",
    code: "GT",
    dial_code: "+502",
  },
  {
    name: "Guernsey",
    flag: "\uD83C\uDDEC\uD83C\uDDEC",
    code: "GG",
    dial_code: "+44",
  },
  {
    name: "Guinea",
    flag: "\uD83C\uDDEC\uD83C\uDDF3",
    code: "GN",
    dial_code: "+224",
  },
  {
    name: "Guinea-Bissau",
    flag: "\uD83C\uDDEC\uD83C\uDDFC",
    code: "GW",
    dial_code: "+245",
  },
  {
    name: "Guyana",
    flag: "\uD83C\uDDEC\uD83C\uDDFE",
    code: "GY",
    dial_code: "+592",
  },
  {
    name: "Haiti",
    flag: "\uD83C\uDDED\uD83C\uDDF9",
    code: "HT",
    dial_code: "+509",
  },
  {
    name: "Heard Island and Mcdonald Islands",
    flag: "\uD83C\uDDED\uD83C\uDDF2",
    code: "HM",
    dial_code: "+672",
  },
  {
    name: "Holy See (Vatican City State)",
    flag: "\uD83C\uDDFB\uD83C\uDDE6",
    code: "VA",
    dial_code: "+379",
  },
  {
    name: "Honduras",
    flag: "\uD83C\uDDED\uD83C\uDDF3",
    code: "HN",
    dial_code: "+504",
  },
  {
    name: "Hong Kong",
    flag: "\uD83C\uDDED\uD83C\uDDF0",
    code: "HK",
    dial_code: "+852",
  },
  {
    name: "Hungary",
    flag: "\uD83C\uDDED\uD83C\uDDFA",
    code: "HU",
    dial_code: "+36",
  },
  {
    name: "Iceland",
    flag: "\uD83C\uDDEE\uD83C\uDDF8",
    code: "IS",
    dial_code: "+354",
  },
  {
    name: "India",
    flag: "\uD83C\uDDEE\uD83C\uDDF3",
    code: "IN",
    dial_code: "+91",
  },
  {
    name: "Indonesia",
    flag: "\uD83C\uDDEE\uD83C\uDDE9",
    code: "ID",
    dial_code: "+62",
  },
  {
    name: "Iran, Islamic Republic of Persian Gulf",
    flag: "\uD83C\uDDEE\uD83C\uDDF7",
    code: "IR",
    dial_code: "+98",
  },
  {
    name: "Iraq",
    flag: "\uD83C\uDDEE\uD83C\uDDF6",
    code: "IQ",
    dial_code: "+964",
  },
  {
    name: "Ireland",
    flag: "\uD83C\uDDEE\uD83C\uDDEA",
    code: "IE",
    dial_code: "+353",
  },
  {
    name: "Isle of Man",
    flag: "\uD83C\uDDEE\uD83C\uDDF2",
    code: "IM",
    dial_code: "+44",
  },
  {
    name: "Israel",
    flag: "\uD83C\uDDEE\uD83C\uDDF1",
    code: "IL",
    dial_code: "+972",
  },
  {
    name: "Italy",
    flag: "\uD83C\uDDEE\uD83C\uDDF9",
    code: "IT",
    dial_code: "+39",
  },
  {
    name: "Jamaica",
    flag: "\uD83C\uDDEF\uD83C\uDDF2",
    code: "JM",
    dial_code: "+1876",
  },
  {
    name: "Japan",
    flag: "\uD83C\uDDEF\uD83C\uDDF5",
    code: "JP",
    dial_code: "+81",
  },
  {
    name: "Jersey",
    flag: "\uD83C\uDDEF\uD83C\uDDEA",
    code: "JE",
    dial_code: "+44",
  },
  {
    name: "Jordan",
    flag: "\uD83C\uDDEF\uD83C\uDDF4",
    code: "JO",
    dial_code: "+962",
  },
  {
    name: "Kazakhstan",
    flag: "\uD83C\uDDF0\uD83C\uDDFF",
    code: "KZ",
    dial_code: "+7",
  },
  {
    name: "Kenya",
    flag: "\uD83C\uDDF0\uD83C\uDDEA",
    code: "KE",
    dial_code: "+254",
  },
  {
    name: "Kiribati",
    flag: "\uD83C\uDDF0\uD83C\uDDEE",
    code: "KI",
    dial_code: "+686",
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    flag: "\uD83C\uDDF0\uD83C\uDDF5",
    code: "KP",
    dial_code: "+850",
  },
  {
    name: "Korea, Republic of South Korea",
    flag: "\uD83C\uDDF0\uD83C\uDDF7",
    code: "KR",
    dial_code: "+82",
  },
  {
    name: "Kosovo",
    flag: "\uD83C\uDDFD\uD83C\uDDF0",
    code: "XK",
    dial_code: "+383",
  },
  {
    name: "Kuwait",
    flag: "\uD83C\uDDF0\uD83C\uDDFC",
    code: "KW",
    dial_code: "+965",
  },
  {
    name: "Kyrgyzstan",
    flag: "\uD83C\uDDF0\uD83C\uDDEC",
    code: "KG",
    dial_code: "+996",
  },
  {
    name: "Laos",
    flag: "\uD83C\uDDF1\uD83C\uDDE6",
    code: "LA",
    dial_code: "+856",
  },
  {
    name: "Latvia",
    flag: "\uD83C\uDDF1\uD83C\uDDFB",
    code: "LV",
    dial_code: "+371",
  },
  {
    name: "Lebanon",
    flag: "\uD83C\uDDF1\uD83C\uDDE7",
    code: "LB",
    dial_code: "+961",
  },
  {
    name: "Lesotho",
    flag: "\uD83C\uDDF1\uD83C\uDDF8",
    code: "LS",
    dial_code: "+266",
  },
  {
    name: "Liberia",
    flag: "\uD83C\uDDF1\uD83C\uDDF7",
    code: "LR",
    dial_code: "+231",
  },
  {
    name: "Libyan Arab Jamahiriya",
    flag: "\uD83C\uDDF1\uD83C\uDDFE",
    code: "LY",
    dial_code: "+218",
  },
  {
    name: "Liechtenstein",
    flag: "\uD83C\uDDF1\uD83C\uDDEE",
    code: "LI",
    dial_code: "+423",
  },
  {
    name: "Lithuania",
    flag: "\uD83C\uDDF1\uD83C\uDDF9",
    code: "LT",
    dial_code: "+370",
  },
  {
    name: "Luxembourg",
    flag: "\uD83C\uDDF1\uD83C\uDDFA",
    code: "LU",
    dial_code: "+352",
  },
  {
    name: "Macao",
    flag: "\uD83C\uDDF2\uD83C\uDDF4",
    code: "MO",
    dial_code: "+853",
  },
  {
    name: "Macedonia",
    flag: "\uD83C\uDDF2\uD83C\uDDF0",
    code: "MK",
    dial_code: "+389",
  },
  {
    name: "Madagascar",
    flag: "\uD83C\uDDF2\uD83C\uDDEC",
    code: "MG",
    dial_code: "+261",
  },
  {
    name: "Malawi",
    flag: "\uD83C\uDDF2\uD83C\uDDFC",
    code: "MW",
    dial_code: "+265",
  },
  {
    name: "Malaysia",
    flag: "\uD83C\uDDF2\uD83C\uDDFE",
    code: "MY",
    dial_code: "+60",
  },
  {
    name: "Maldives",
    flag: "\uD83C\uDDF2\uD83C\uDDFB",
    code: "MV",
    dial_code: "+960",
  },
  {
    name: "Mali",
    flag: "\uD83C\uDDF2\uD83C\uDDF1",
    code: "ML",
    dial_code: "+223",
  },
  {
    name: "Malta",
    flag: "\uD83C\uDDF2\uD83C\uDDF9",
    code: "MT",
    dial_code: "+356",
  },
  {
    name: "Marshall Islands",
    flag: "\uD83C\uDDF2\uD83C\uDDED",
    code: "MH",
    dial_code: "+692",
  },
  {
    name: "Martinique",
    flag: "\uD83C\uDDF2\uD83C\uDDF6",
    code: "MQ",
    dial_code: "+596",
  },
  {
    name: "Mauritania",
    flag: "\uD83C\uDDF2\uD83C\uDDF7",
    code: "MR",
    dial_code: "+222",
  },
  {
    name: "Mauritius",
    flag: "\uD83C\uDDF2\uD83C\uDDFA",
    code: "MU",
    dial_code: "+230",
  },
  {
    name: "Mayotte",
    flag: "\uD83C\uDDFE\uD83C\uDDF9",
    code: "YT",
    dial_code: "+262",
  },
  {
    name: "Mexico",
    flag: "\uD83C\uDDF2\uD83C\uDDFD",
    code: "MX",
    dial_code: "+52",
  },
  {
    name: "Micronesia, Federated States of Micronesia",
    flag: "\uD83C\uDDEB\uD83C\uDDF2",
    code: "FM",
    dial_code: "+691",
  },
  {
    name: "Moldova",
    flag: "\uD83C\uDDF2\uD83C\uDDE9",
    code: "MD",
    dial_code: "+373",
  },
  {
    name: "Monaco",
    flag: "\uD83C\uDDF2\uD83C\uDDE8",
    code: "MC",
    dial_code: "+377",
  },
  {
    name: "Mongolia",
    flag: "\uD83C\uDDF2\uD83C\uDDF3",
    code: "MN",
    dial_code: "+976",
  },
  {
    name: "Montenegro",
    flag: "\uD83C\uDDF2\uD83C\uDDEA",
    code: "ME",
    dial_code: "+382",
  },
  {
    name: "Montserrat",
    flag: "\uD83C\uDDF2\uD83C\uDDF8",
    code: "MS",
    dial_code: "+1664",
  },
  {
    name: "Morocco",
    flag: "\uD83C\uDDF2\uD83C\uDDE6",
    code: "MA",
    dial_code: "+212",
  },
  {
    name: "Mozambique",
    flag: "\uD83C\uDDF2\uD83C\uDDFF",
    code: "MZ",
    dial_code: "+258",
  },
  {
    name: "Myanmar",
    flag: "\uD83C\uDDF2\uD83C\uDDF2",
    code: "MM",
    dial_code: "+95",
  },
  {
    name: "Namibia",
    flag: "\uD83C\uDDF3\uD83C\uDDE6",
    code: "NA",
    dial_code: "+264",
  },
  {
    name: "Nauru",
    flag: "\uD83C\uDDF3\uD83C\uDDF7",
    code: "NR",
    dial_code: "+674",
  },
  {
    name: "Nepal",
    flag: "\uD83C\uDDF3\uD83C\uDDF5",
    code: "NP",
    dial_code: "+977",
  },
  {
    name: "Netherlands",
    flag: "\uD83C\uDDF3\uD83C\uDDF1",
    code: "NL",
    dial_code: "+31",
  },
  { name: "Netherlands Antilles", flag: "", code: "AN", dial_code: "+599" },
  {
    name: "New Caledonia",
    flag: "\uD83C\uDDF3\uD83C\uDDE8",
    code: "NC",
    dial_code: "+687",
  },
  {
    name: "New Zealand",
    flag: "\uD83C\uDDF3\uD83C\uDDFF",
    code: "NZ",
    dial_code: "+64",
  },
  {
    name: "Nicaragua",
    flag: "\uD83C\uDDF3\uD83C\uDDEE",
    code: "NI",
    dial_code: "+505",
  },
  {
    name: "Niger",
    flag: "\uD83C\uDDF3\uD83C\uDDEA",
    code: "NE",
    dial_code: "+227",
  },
  {
    name: "Nigeria",
    flag: "\uD83C\uDDF3\uD83C\uDDEC",
    code: "NG",
    dial_code: "+234",
  },
  {
    name: "Niue",
    flag: "\uD83C\uDDF3\uD83C\uDDFA",
    code: "NU",
    dial_code: "+683",
  },
  {
    name: "Norfolk Island",
    flag: "\uD83C\uDDF3\uD83C\uDDEB",
    code: "NF",
    dial_code: "+672",
  },
  {
    name: "Northern Mariana Islands",
    flag: "\uD83C\uDDF2\uD83C\uDDF5",
    code: "MP",
    dial_code: "+1670",
  },
  {
    name: "Norway",
    flag: "\uD83C\uDDF3\uD83C\uDDF4",
    code: "NO",
    dial_code: "+47",
  },
  {
    name: "Oman",
    flag: "\uD83C\uDDF4\uD83C\uDDF2",
    code: "OM",
    dial_code: "+968",
  },
  {
    name: "Pakistan",
    flag: "\uD83C\uDDF5\uD83C\uDDF0",
    code: "PK",
    dial_code: "+92",
  },
  {
    name: "Palau",
    flag: "\uD83C\uDDF5\uD83C\uDDFC",
    code: "PW",
    dial_code: "+680",
  },
  {
    name: "Palestinian Territory, Occupied",
    flag: "\uD83C\uDDF5\uD83C\uDDF8",
    code: "PS",
    dial_code: "+970",
  },
  {
    name: "Panama",
    flag: "\uD83C\uDDF5\uD83C\uDDE6",
    code: "PA",
    dial_code: "+507",
  },
  {
    name: "Papua New Guinea",
    flag: "\uD83C\uDDF5\uD83C\uDDEC",
    code: "PG",
    dial_code: "+675",
  },
  {
    name: "Paraguay",
    flag: "\uD83C\uDDF5\uD83C\uDDFE",
    code: "PY",
    dial_code: "+595",
  },
  {
    name: "Peru",
    flag: "\uD83C\uDDF5\uD83C\uDDEA",
    code: "PE",
    dial_code: "+51",
  },
  {
    name: "Philippines",
    flag: "\uD83C\uDDF5\uD83C\uDDED",
    code: "PH",
    dial_code: "+63",
  },
  {
    name: "Pitcairn",
    flag: "\uD83C\uDDF5\uD83C\uDDF3",
    code: "PN",
    dial_code: "+64",
  },
  {
    name: "Poland",
    flag: "\uD83C\uDDF5\uD83C\uDDF1",
    code: "PL",
    dial_code: "+48",
  },
  {
    name: "Portugal",
    flag: "\uD83C\uDDF5\uD83C\uDDF9",
    code: "PT",
    dial_code: "+351",
  },
  {
    name: "Puerto Rico",
    flag: "\uD83C\uDDF5\uD83C\uDDF7",
    code: "PR",
    dial_code: "+1939",
  },
  {
    name: "Qatar",
    flag: "\uD83C\uDDF6\uD83C\uDDE6",
    code: "QA",
    dial_code: "+974",
  },
  {
    name: "Romania",
    flag: "\uD83C\uDDF7\uD83C\uDDF4",
    code: "RO",
    dial_code: "+40",
  },
  {
    name: "Russia",
    flag: "\uD83C\uDDF7\uD83C\uDDFA",
    code: "RU",
    dial_code: "+7",
  },
  {
    name: "Rwanda",
    flag: "\uD83C\uDDF7\uD83C\uDDFC",
    code: "RW",
    dial_code: "+250",
  },
  {
    name: "Reunion",
    flag: "\uD83C\uDDF7\uD83C\uDDEA",
    code: "RE",
    dial_code: "+262",
  },
  {
    name: "Saint Barthelemy",
    flag: "\uD83C\uDDE7\uD83C\uDDF1",
    code: "BL",
    dial_code: "+590",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    flag: "\uD83C\uDDF8\uD83C\uDDED",
    code: "SH",
    dial_code: "+290",
  },
  {
    name: "Saint Kitts and Nevis",
    flag: "\uD83C\uDDF0\uD83C\uDDF3",
    code: "KN",
    dial_code: "+1869",
  },
  {
    name: "Saint Lucia",
    flag: "\uD83C\uDDF1\uD83C\uDDE8",
    code: "LC",
    dial_code: "+1758",
  },
  {
    name: "Saint Martin",
    flag: "\uD83C\uDDF2\uD83C\uDDEB",
    code: "MF",
    dial_code: "+590",
  },
  {
    name: "Saint Pierre and Miquelon",
    flag: "\uD83C\uDDF5\uD83C\uDDF2",
    code: "PM",
    dial_code: "+508",
  },
  {
    name: "Saint Vincent and the Grenadines",
    flag: "\uD83C\uDDFB\uD83C\uDDE8",
    code: "VC",
    dial_code: "+1784",
  },
  {
    name: "Samoa",
    flag: "\uD83C\uDDFC\uD83C\uDDF8",
    code: "WS",
    dial_code: "+685",
  },
  {
    name: "San Marino",
    flag: "\uD83C\uDDF8\uD83C\uDDF2",
    code: "SM",
    dial_code: "+378",
  },
  {
    name: "Sao Tome and Principe",
    flag: "\uD83C\uDDF8\uD83C\uDDF9",
    code: "ST",
    dial_code: "+239",
  },
  {
    name: "Saudi Arabia",
    flag: "\uD83C\uDDF8\uD83C\uDDE6",
    code: "SA",
    dial_code: "+966",
  },
  {
    name: "Senegal",
    flag: "\uD83C\uDDF8\uD83C\uDDF3",
    code: "SN",
    dial_code: "+221",
  },
  {
    name: "Serbia",
    flag: "\uD83C\uDDF7\uD83C\uDDF8",
    code: "RS",
    dial_code: "+381",
  },
  {
    name: "Seychelles",
    flag: "\uD83C\uDDF8\uD83C\uDDE8",
    code: "SC",
    dial_code: "+248",
  },
  {
    name: "Sierra Leone",
    flag: "\uD83C\uDDF8\uD83C\uDDF1",
    code: "SL",
    dial_code: "+232",
  },
  {
    name: "Singapore",
    flag: "\uD83C\uDDF8\uD83C\uDDEC",
    code: "SG",
    dial_code: "+65",
  },
  {
    name: "Slovakia",
    flag: "\uD83C\uDDF8\uD83C\uDDF0",
    code: "SK",
    dial_code: "+421",
  },
  {
    name: "Slovenia",
    flag: "\uD83C\uDDF8\uD83C\uDDEE",
    code: "SI",
    dial_code: "+386",
  },
  {
    name: "Solomon Islands",
    flag: "\uD83C\uDDF8\uD83C\uDDE7",
    code: "SB",
    dial_code: "+677",
  },
  {
    name: "Somalia",
    flag: "\uD83C\uDDF8\uD83C\uDDF4",
    code: "SO",
    dial_code: "+252",
  },
  {
    name: "South Africa",
    flag: "\uD83C\uDDFF\uD83C\uDDE6",
    code: "ZA",
    dial_code: "+27",
  },
  {
    name: "South Sudan",
    flag: "\uD83C\uDDF8\uD83C\uDDF8",
    code: "SS",
    dial_code: "+211",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    flag: "\uD83C\uDDEC\uD83C\uDDF8",
    code: "GS",
    dial_code: "+500",
  },
  {
    name: "Spain",
    flag: "\uD83C\uDDEA\uD83C\uDDF8",
    code: "ES",
    dial_code: "+34",
  },
  {
    name: "Sri Lanka",
    flag: "\uD83C\uDDF1\uD83C\uDDF0",
    code: "LK",
    dial_code: "+94",
  },
  {
    name: "Sudan",
    flag: "\uD83C\uDDF8\uD83C\uDDE9",
    code: "SD",
    dial_code: "+249",
  },
  {
    name: "Suriname",
    flag: "\uD83C\uDDF8\uD83C\uDDF7",
    code: "SR",
    dial_code: "+597",
  },
  {
    name: "Svalbard and Jan Mayen",
    flag: "\uD83C\uDDF8\uD83C\uDDEF",
    code: "SJ",
    dial_code: "+47",
  },
  {
    name: "Eswatini",
    flag: "\uD83C\uDDF8\uD83C\uDDFF",
    code: "SZ",
    dial_code: "+268",
  },
  {
    name: "Sweden",
    flag: "\uD83C\uDDF8\uD83C\uDDEA",
    code: "SE",
    dial_code: "+46",
  },
  {
    name: "Switzerland",
    flag: "\uD83C\uDDE8\uD83C\uDDED",
    code: "CH",
    dial_code: "+41",
  },
  {
    name: "Syrian Arab Republic",
    flag: "\uD83C\uDDF8\uD83C\uDDFE",
    code: "SY",
    dial_code: "+963",
  },
  {
    name: "Taiwan",
    flag: "\uD83C\uDDF9\uD83C\uDDFC",
    code: "TW",
    dial_code: "+886",
  },
  {
    name: "Tajikistan",
    flag: "\uD83C\uDDF9\uD83C\uDDEF",
    code: "TJ",
    dial_code: "+992",
  },
  {
    name: "Tanzania, United Republic of Tanzania",
    flag: "\uD83C\uDDF9\uD83C\uDDFF",
    code: "TZ",
    dial_code: "+255",
  },
  {
    name: "Thailand",
    flag: "\uD83C\uDDF9\uD83C\uDDED",
    code: "TH",
    dial_code: "+66",
  },
  {
    name: "Timor-Leste",
    flag: "\uD83C\uDDF9\uD83C\uDDF1",
    code: "TL",
    dial_code: "+670",
  },
  {
    name: "Togo",
    flag: "\uD83C\uDDF9\uD83C\uDDEC",
    code: "TG",
    dial_code: "+228",
  },
  {
    name: "Tokelau",
    flag: "\uD83C\uDDF9\uD83C\uDDF0",
    code: "TK",
    dial_code: "+690",
  },
  {
    name: "Tonga",
    flag: "\uD83C\uDDF9\uD83C\uDDF4",
    code: "TO",
    dial_code: "+676",
  },
  {
    name: "Trinidad and Tobago",
    flag: "\uD83C\uDDF9\uD83C\uDDF9",
    code: "TT",
    dial_code: "+1868",
  },
  {
    name: "Tunisia",
    flag: "\uD83C\uDDF9\uD83C\uDDF3",
    code: "TN",
    dial_code: "+216",
  },
  {
    name: "Turkey",
    flag: "\uD83C\uDDF9\uD83C\uDDF7",
    code: "TR",
    dial_code: "+90",
  },
  {
    name: "Turkmenistan",
    flag: "\uD83C\uDDF9\uD83C\uDDF2",
    code: "TM",
    dial_code: "+993",
  },
  {
    name: "Turks and Caicos Islands",
    flag: "\uD83C\uDDF9\uD83C\uDDE8",
    code: "TC",
    dial_code: "+1649",
  },
  {
    name: "Tuvalu",
    flag: "\uD83C\uDDF9\uD83C\uDDFB",
    code: "TV",
    dial_code: "+688",
  },
  {
    name: "Uganda",
    flag: "\uD83C\uDDFA\uD83C\uDDEC",
    code: "UG",
    dial_code: "+256",
  },
  {
    name: "Ukraine",
    flag: "\uD83C\uDDFA\uD83C\uDDE6",
    code: "UA",
    dial_code: "+380",
  },
  {
    name: "United Arab Emirates",
    flag: "\uD83C\uDDE6\uD83C\uDDEA",
    code: "AE",
    dial_code: "+971",
  },
  {
    name: "United Kingdom",
    flag: "\uD83C\uDDEC\uD83C\uDDE7",
    code: "GB",
    dial_code: "+44",
  },
  {
    name: "United States",
    flag: "\uD83C\uDDFA\uD83C\uDDF8",
    code: "US",
    dial_code: "+1",
  },
  {
    name: "Uruguay",
    flag: "\uD83C\uDDFA\uD83C\uDDFE",
    code: "UY",
    dial_code: "+598",
  },
  {
    name: "Uzbekistan",
    flag: "\uD83C\uDDFA\uD83C\uDDFF",
    code: "UZ",
    dial_code: "+998",
  },
  {
    name: "Vanuatu",
    flag: "\uD83C\uDDFB\uD83C\uDDFA",
    code: "VU",
    dial_code: "+678",
  },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    flag: "\uD83C\uDDFB\uD83C\uDDEA",
    code: "VE",
    dial_code: "+58",
  },
  {
    name: "Vietnam",
    flag: "\uD83C\uDDFB\uD83C\uDDF3",
    code: "VN",
    dial_code: "+84",
  },
  {
    name: "Virgin Islands, British",
    flag: "\uD83C\uDDFB\uD83C\uDDEC",
    code: "VG",
    dial_code: "+1284",
  },
  {
    name: "Virgin Islands, U.S.",
    flag: "\uD83C\uDDFB\uD83C\uDDEE",
    code: "VI",
    dial_code: "+1340",
  },
  {
    name: "Wallis and Futuna",
    flag: "\uD83C\uDDFC\uD83C\uDDEB",
    code: "WF",
    dial_code: "+681",
  },
  {
    name: "Yemen",
    flag: "\uD83C\uDDFE\uD83C\uDDEA",
    code: "YE",
    dial_code: "+967",
  },
  {
    name: "Zambia",
    flag: "\uD83C\uDDFF\uD83C\uDDF2",
    code: "ZM",
    dial_code: "+260",
  },
  {
    name: "Zimbabwe",
    flag: "\uD83C\uDDFF\uD83C\uDDFC",
    code: "ZW",
    dial_code: "+263",
  },
];
export const language = [
  {
    name: "English",
    flag: "https://flagsapi.com/GB/flat/64.png",
    sort_name: "en",
  },
  {
    name: "Arabic",
    flag: "https://flagsapi.com/SA/flat/64.png",
    sort_name: "ar",
  },
  {
    name: "French",
    flag: "https://flagsapi.com/FR/flat/64.png",
    sort_name: "fr",
  },
  {
    name: "Russian",
    flag: "https://flagsapi.com/RU/flat/64.png",
    sort_name: "ru",
  },
  {
    name: "Tagalog",
    flag: "https://flagsapi.com/PH/flat/64.png",
    sort_name: "tl",
  },
  {
    name: "Indonesian",
    flag: "https://flagsapi.com/ID/flat/64.png",
    sort_name: "id",
  },
  {
    name: "Malay",
    flag: "https://flagsapi.com/MY/flat/64.png",
    sort_name: "ms",
  },
  {
    name: "Bengali",
    flag: "https://flagsapi.com/BD/flat/64.png",
    sort_name: "bn",
  },
  {
    name: "Amharic",
    flag: "https://flagsapi.com/ET/flat/64.png",
    sort_name: "am",
  },
  {
    name: "Kazakh",
    flag: "https://flagsapi.com/KZ/flat/64.png",
    sort_name: "kz",
  },
  {
    name: "Uzbek",
    flag: "https://flagsapi.com/UZ/flat/64.png",
    sort_name: "uz",
  },
  {
    name: "Swahili",
    flag: "https://flagsapi.com/TZ/flat/64.png",
    sort_name: "tz",
  },
  {
    name: "Hausa",
    flag: "https://flagsapi.com/NG/flat/64.png",
    sort_name: "ha",
  },
  {
    name: "Japanese",
    flag: "https://flagsapi.com/JP/flat/64.png",
    sort_name: "ja",
  },
  {
    name: "Spanish",
    flag: "https://flagsapi.com/ES/flat/64.png",
    sort_name: "es",
  },
  {
    name: "Portuguese",
    flag: "https://flagsapi.com/PT/flat/64.png",
    sort_name: "pt",
  },
];
